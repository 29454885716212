import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "What Is Google App Engine Used For?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Google App Engine (GAP) is a platform that offers cloud computing for developing and hosting web applications in Google-managed data centers."
        }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "What Languages Are Supported By Google App Engine?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Currently, the Google App engine supports Node.js, Go, PHP, Python and Java. Also, your website templates can include JavaScript and HTML, which among other things, allows you to write AJAX-enabled web application. You don’t have to worry about anything because Codestaff will take care of it for you."
        }
    }
}

const GoogleAppEngineApi = () => (
    <main>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
        </Helmet>
        <section className="cx_catagories">
            <div className="container-fluid cx_top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-sm-10">
                            <h2>
                                Hire top freelance<br /><span> Google App Engine API Experts.</span>
                            </h2>
                            <p>
                                Codestaff is a marketplace for top Google App Engine API Experts. Top
                                companies and start-ups choose Codestaff Google App Engine API Experts
                                for their mission critical software projects.
                            </p>
                            <Link to="/hire/" className="skills-banner-btn">
                                Hire a top Google App Engine API Expert now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <CxSearch title='HIRE FREELANCE GOOGLE APP ENGINE API EXPERTS' banner='/google-app-engine-api.png' bannerAlt='google app engine api banner' />
        </section>
        <HireAndApply />
        <TopTrendingSkills />
    </main>
)

export default GoogleAppEngineApi

import React from 'react'
import Layout from '../components/layout'
import GoogleAppEngineApi from '../components/categories/google-app-engine-api'
import Seo from '../components/seo'

const GoogleAppEngineApiPage = ({ location }) => {
  return (
    <Layout location={location}>
      <GoogleAppEngineApi />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Google App Engine Api Freelancers | Codestaff'
  const description =
    'Hire the best Google App Engine Api freelancers at Codestaff. Get the top 1% of Google App Engine Api professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default GoogleAppEngineApiPage
